const globalPermissionsArray =
  [
    {
      header: 'Overall Site Permissions'
    },
    {
      text: 'Member',
      index: 0
    },
    {
      text: 'Manager',
      index: 1
    },
    {
      text: 'Administrator',
      index: 2
    },
    {
      text: 'Root',
      index: 3
    },
    {
      text: 'Show In Agent List',
      index: 4
    },
    {
      text: 'Show Currency',
      index: 5
    },
    {
      header: 'Menu Permissions'
    },
    {
      text: 'Dashboard',
      index: 6
    },
    {
      text: 'Purchases',
      index: 7
    },
    {
      text: 'Sales',
      index: 8
    },
    {
      text: 'Projections',
      index: 9
    },
    {
      text: 'Projection Reports',
      index: 10
    },
    {
      text: 'Sales Library',
      index: 11
    },
    {
      text: 'Reports',
      index: 12
    },
    {
      header: 'Business Alert Permissions'
    },
    {
      text: 'New Business Alert',
      index: 13
    },
    {
      text: 'Edit Need Date',
      index: 14
    },
    {
      text: 'Research',
      index: 15
    },
    {
      text: 'Quality',
      index: 16
    },
    {
      text: 'Purchasing',
      index: 17
    },
    {
      text: 'Customer Service',
      index: 18
    },
    {
      text: 'Operations',
      index: 19
    },
    {
      text: 'Can Archive',
      index: 20
    },
    {
      header: 'Formulation Permissions'
    },
    {
      text: 'View Formulations',
      index: 21
    },
    {
      text: 'Edit Formulations',
      index: 22
    },
    {
      header: 'Export Permissions'
    },
    {
      text: 'Allow CSV Export',
      index: 23
    },
    {
      text: 'Allow PDF Export',
      index: 24
    },
    {
      header: 'Location Restrictions'
    },
    {
      text: 'Restrict FMI',
      index: 25
    },
    {
      text: 'Restrict SMG',
      index: 26
    },
    {
      text: 'Restrict LTCA',
      index: 27
    }
  ]

export default globalPermissionsArray