<template>
  <v-container fluid>
    <v-snackbar v-model="sb_model" top :color="sb_color" :timeout="sb_delay">
      {{ sb_msg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="sb_model = false">Close</v-btn>
      </template>
    </v-snackbar>
    <v-tabs v-model="tabs" light color="blue" fixed-tabs show-arrows>
      <v-tab>Users</v-tab>
      <v-tab>Slack</v-tab>
      <v-tab>Settings</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <!-- data table -->
        <v-data-table id="nbatable" :headers="adminTableHeaders" :items="adminTableData" :items-per-page="10" dense
          item-key="field1" :search="adminSearch" :loading="adminLoading" class="mt-5">
          <template v-slot:top>
            <v-toolbar color="red darken-4 elevation-0" dark>
              <v-toolbar-title>Portal Administration</v-toolbar-title>
              <v-divider class="mx-2 grey" inset vertical></v-divider>
              <v-btn outlined text class="ml-2 red lighten-2" @click="load()">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <v-btn outlined text class="ml-2 red lighten-2" @click="newUser">
                <v-icon>mdi-account-plus</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-text-field class="mt-6" v-model="adminSearch" label="Search"></v-text-field>
              <v-btn outlined text @click="rightdrawer = !rightdrawer" class="ml-3 red"> <v-icon light>mdi-menu</v-icon> </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.Permissions="{ item }"><span class="secondary--text">{{item.Permissions}}</span></template>
          <!-- table actions CRUD -->
          <template v-slot:item.actions="{ item }">

            <v-tooltip bottom open-delay="500">
              <template v-slot:activator="{ on }">
                <v-icon tag="Edit User" class="mr-2" @click="editUser(item)" v-on="on">mdi-pencil</v-icon>
              </template>
              <span>Edit User</span>
            </v-tooltip>

            <v-icon small :color="item.IsActive == 64 ? 'green':'red'" tag="Delete User" class="mr-2" @click="disableUser(item)" :disabled="!(item.Access < 99)">mdi-account-cancel-outline</v-icon>

            <v-tooltip bottom open-delay="500">
              <template v-slot:activator="{ on }"><v-icon small tag="Reset Account" class="mr-2" @click="resetUser(item)" v-on="on">mdi-lock-reset</v-icon></template>
              <span>Reset Account Password</span>
            </v-tooltip>

            <v-icon class="mr-2" :class="item.TwoFactor ? 'black--text' : 'grey--text text--lighten-2'">mdi-two-factor-authentication</v-icon>

            <v-tooltip bottom open-delay="500">
              <template v-slot:activator="{ on }"><v-icon small color="red lighten-3" tag="Delete Account" class="mr-2" @click="deleteUserDialog()" v-on="on">mdi-account-minus</v-icon></template>
              <span>Delete Account</span>
            </v-tooltip>

          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item>
        <!-- slack integration -->
        <v-row class="mt-5">
          <v-col cols="7" offset-md="1">
            <v-autocomplete :items="slackUserList" clearable v-model="fobpick" label="Slack Identity"
              :search-input.sync="autocompletePick" class="fun-color" hide-selected placeholder="Start typing to Search"
              persistent-hint hint="Find user to add." :error-messages="autocompleteError"></v-autocomplete>
          </v-col>
          <v-col>
            <v-btn @click="saveTables()" class="success mt-4">Update</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" offset-md="1" style="border-top:solid 1px #bbb">
            <v-btn text block class="text-h5 blue--text text--darken-3" @click="addtoNJ()">
              <v-icon>mdi-account-plus-outline</v-icon> Slack User Map/FOB NJ
            </v-btn>
            <v-btn block x-small text outlined @click="seedSMG()">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-data-table :headers="slackHeaders" :items="fobListNJ" :items-per-page="5" :loading="slackloading"
              :footer-props="{
                            'items-per-page-options': [5],
                            showFirstLastPage: true,
                            firstIcon: 'mdi-arrow-collapse-left',
                            lastIcon: 'mdi-arrow-collapse-right',
                            prevIcon: 'mdi-minus',
                            nextIcon: 'mdi-plus'}">
              <template v-slot:item.actions="{ item }">
                <v-icon color="grey" @click="deleteUser(item)">mdi-delete</v-icon>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="5" style="border-top:solid 1px #bbb">
            <v-btn text block class="text-h5 green--text test--darken-3" @click="addtoSMG()">
              <v-icon>mdi-account-plus-outline</v-icon> Slack User Map/FOB SMG
            </v-btn>
            <v-btn block x-small text outlined @click="seedNJ()">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-data-table :headers="slackHeaders" :items="fobListSMG" :items-per-page="5" :loading="slackloading"
              :footer-props="{
                            'items-per-page-options': [5],
                            showFirstLastPage: true,
                            firstIcon: 'mdi-arrow-collapse-left',
                            lastIcon: 'mdi-arrow-collapse-right',
                            prevIcon: 'mdi-minus',
                            nextIcon: 'mdi-plus'}">
              <template v-slot:item.actions="{ item }">
                <v-icon color="grey" @click="deleteUser(item)">mdi-delete</v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col md="2" offset-md="1">
            <v-switch :loading="savingState" v-model="override" @change="enableOverride" label="Override"
              :hint="hintText" persistent-hint></v-switch>
          </v-col>
          <v-col md="2">
            <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :return-value.sync="quarterlyCutoff[1]"
              transition="scale-transition" offset-y min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field class="mt-3" v-model="quarterlyCutoff[1]" label="1st Quarter" prepend-icon="event"
                  readonly v-bind="attrs" v-on="on" :disabled="!override"></v-text-field>
              </template>
              <v-date-picker v-model="quarterlyCutoff[1]" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu1 = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.menu1.save(quarterlyCutoff[1])">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="2">
            <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :return-value.sync="quarterlyCutoff[2]"
              transition="scale-transition" offset-y min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field class="mt-3" v-model="quarterlyCutoff[2]" label="2nd Quarter" prepend-icon="event"
                  readonly v-bind="attrs" v-on="on" :disabled="!override"></v-text-field>
              </template>
              <v-date-picker v-model="quarterlyCutoff[2]" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu2 = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.menu2.save(quarterlyCutoff[2])">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="2">
            <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" :return-value.sync="quarterlyCutoff[3]"
              transition="scale-transition" offset-y min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field class="mt-3" v-model="quarterlyCutoff[3]" label="3rd Quarter" prepend-icon="event"
                  readonly v-bind="attrs" v-on="on" :disabled="!override"></v-text-field>
              </template>
              <v-date-picker v-model="quarterlyCutoff[3]" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu3 = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.menu3.save(quarterlyCutoff[3])">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="2">
            <v-menu ref="menu4" v-model="menu4" :close-on-content-click="false" :return-value.sync="quarterlyCutoff[4]"
              transition="scale-transition" offset-y min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field class="mt-3" v-model="quarterlyCutoff[4]" label="4th Quarter" prepend-icon="event"
                  readonly v-bind="attrs" v-on="on" :disabled="!override"></v-text-field>
              </template>
              <v-date-picker v-model="quarterlyCutoff[4]" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu4 = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.menu4.save(quarterlyCutoff[4])">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog v-model="userDialog" persistent max-width="800px">
      <v-card>
        <v-card-title>User Profile <span class="ml-2 mt-1 caption secondary--text">({{decimalPermissions}})</span></v-card-title>
        <v-card-text>
          <v-container style="padding:0!important:margin:0!important">

            <v-row dense>
              <v-col cols="12" sm="4" md="4">
                <v-text-field v-model="edited.First" label="First name" required counter="25" autocomplete="off">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field v-model="edited.Last" label="Last name" required counter="25" autocomplete="off">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field label="NAV Initials" v-model="edited.NavInitials" required counter="4"
                  autocomplete="off"></v-text-field>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="8">
                <v-text-field v-model="edited.Email" label="Email" required counter="75" autocomplete="off">
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select disabled :items="accessList" v-model="edited.Access" required label="Access" class="mt-5"
                  dense></v-select>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12">
                <v-select v-model="permissions" :items="permissionsArray" :menu-props="{ maxHeight: '400' }" label="Select"
                item-text="text" item-value="index"
                small-chips
                @change="updatePermissionInt"
                multiple hint="Select Site Permissions" persistent-hint>
                <template v-slot:selection="{ item, index }">
                  <v-chip small v-if="index === 0 || index === 1"> <span>{{ item.text }}</span> </v-chip>
                  <span v-if="index === 2" class="grey--text caption">(+{{ permissions.length - 2 }} others)</span>
                </template>
                </v-select>
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="userDialog = false">Close</v-btn>
          <v-btn color="blue darken-1" text @click="updateUser()">{{formAction}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteuserDialog" persistent max-width="400">
      <v-card>
        <v-card-title>Delete User Profile</v-card-title>
        <v-card-text>
          <p>Mark this user as deleted and reassign any activites, projections or reports to another user?</p>

          <v-select :items="salesAgents" v-model="selectAgent" label="Reassign Activities To?" outlined hide-details append-icon="mdi-database-search" clearable>

        </v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="deleteuserDialog = false">Close</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text>Approve</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import axios from 'axios'
import GlobalArrays from './data/arrays'

export default {
  name: 'Admin',
  data: () => ({
    salesAgents: [],
    selectAgent: null,
    deleteuserDialog: false,
    permissions: [],
    adminLoading: false,
    slackloading: false,
    adminSearch: '',
    // snack bar pop ups
    sb_model: false,
    sb_color: '',
    sb_delay: 4000,
    sb_msg: '',
    rightdrawer: false,
    userDialog: false,
    decimalPermissions: 0,
    selectPermissionsArray: [],
    selectMenuArray: [],
    selectNBAPermissionArray: [],
    selectGeneralArray: [],
    copiedPermissions: 0,
    permissionsArray: GlobalArrays.PermissionsArray,
    // main display
    adminTableHeaders: [{
        text: 'Username',
        align: 'left',
        sortable: true,
        value: 'Email'
      },
      {
        text: 'Last Login',
        align: 'left',
        sortable: true,
        value: 'LastLogin'
      },
      {
        text: 'First',
        align: 'left',
        sortable: false,
        value: 'First'
      },
      {
        text: 'Last',
        align: 'left',
        sortable: true,
        value: 'Last'
      },
      {
        text: 'Permissions',
        align: 'left',
        sortable: true,
        value: 'Permissions'
      },
      {
        text: 'NAV',
        align: 'left',
        sortable: false,
        value: 'NavInitials'
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: false,
        value: 'actions'
      },
    ],
    adminTableData: [],
    editedIndex: -1,
    edited: {
      First: '',
      Last: '',
      Email: '',
      Access: 0,
      NavInitials: '',
      SalesAgent: false,
      SecondaryAccessOnly: false,
      slackUserID: ''
    },
    default: {
      First: '',
      Last: '',
      Email: '',
      Access: 0,
      NavInitials: '',
      SalesAgent: false,
      SecondaryAccessOnly: false,
    },
    accessList: [{
      text: 'SuperUser',
      value: 99
    }],
    formAction: 'Update',
    quarterlyCutoff: [],
    override: false,
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    hintText: "Override projection quarterly lockouts.",
    savingState: false,
    slackUserList: [],
    fobListNJ: [],
    fobpick: '',
    autocompletePick: '',
    autocompleteError: '',
    fobListSMG: [],
    tabs: null,
    slackHeaders: [{
        text: 'Real Name',
        align: 'left',
        sortable: true,
        value: 'text'
      },
      {
        text: 'Slack ID',
        align: 'left',
        sortable: true,
        value: 'value'
      },
      {
        text: 'Action',
        align: 'left',
        value: 'actions'
      },
    ]
  }),
  mounted: function () {
    this.load()
  },
  methods: {
    limit(start, end) {
      return this.permissionsArray.slice(start, end)
    },
    updatePermissionInt() {
      let binaryform = ''
      for (let i = 0; i < this.permissionsArray.length; ++i) {
        if (!this.permissionsArray[i].header) {
          binaryform += this.permissions.includes(this.permissionsArray[i].index) ? '1' : '0'
        }
      }
      this.decimalPermissions = parseInt(binaryform, 2)
      this.edited.Permissions = this.decimalPermissions.toString()
    },
    copyCurrentPermissions() {
      let current = parseInt(this.edited.Permissions).toString(2).split('')
      this.permissions = []
      for (let c = 0; c < current.length; ++c) {
        if (current[c] == '1') this.permissions.push(c)
      }
    },
    saveTables: function () {
      this.slackloading = true
      let __fobNJ = Object.keys(this.fobListNJ).map((k) => {
        return this.fobListNJ[k].value
      }).join(",")
      let __fobSMG = Object.keys(this.fobListSMG).map((k) => {
        return this.fobListSMG[k].value
      }).join(",")
      axios.post(`${this.$store.getters.NODEURL}/admin/slackgroup`, {
          __fobNJ: __fobNJ,
          __fobSMG: __fobSMG
        }, {
          withCredentials: true
        })
        .then((result) => {
          console.log(result)
        })
        .catch((err) => {
          console.warn(err)
        })
        .finally(() => {
          this.slackloading = false
        })
    },
    addtoNJ: function () {
      if (this.fobListNJ.length && this.fobListNJ.find(v => v.value == this.fobpick)) {
        this.autocompleteError = 'Name is already in the table.'
        return
      }
      if (this.fobpick) {
        this.fobListNJ.push({
          text: this.autocompletePick,
          value: this.fobpick
        })
        this.fobpick = ''
        this.autocompletePick = ''
        this.autocompleteError = ''
      } else {
        this.autocompleteError = 'Select name from list instead of manually typing.'
      }
    },
    addtoSMG: function () {
      if (this.fobListSMG.length && this.fobListSMG.find(v => v.value == this.fobpick)) {
        this.autocompleteError = 'Name is already in the table.'
        return
      }
      if (this.fobpick) {
        this.fobListSMG.push({
          text: this.autocompletePick,
          value: this.fobpick
        })
        this.fobpick = ''
        this.autocompletePick = ''
        this.autocompleteError = ''
      } else {
        this.autocompleteError = 'Select name from list instead of manually typing.'
      }
    },
    enableOverride: function () {

      let override = []

      const dayofyear = (yr) => {
        var now = new Date(yr)
        var start = new Date(now.getFullYear(), 0, 0)
        var diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000)
        return Math.floor(diff / (1000 * 60 * 60 * 24))
      }

      if (this.override) {
        this.hintText = "Changes will save when locking."
      } else {
        this.savingState = true
        this.hintText = "Saving date settings..."
        for (let i = 1; i < this.quarterlyCutoff.length; ++i) {
          override[i - 1] = dayofyear(this.quarterlyCutoff[i])
        }
        axios.post(`${this.$store.getters.NODEURL}/admin/dates`, {
            override: override
          }, {
            withCredentials: true
          })
          .then((result) => {
            if (result.data.response == 'ok') {
              this.snackbar({
                msg: 'User account was disabled.',
                color: 'success'
              })
            }
          })
          .catch((err) => {
            console.warn(err)
          })
          .finally(() => {
            this.savingState = false
            this.hintText = "Override projection quarterly lockouts."
          })
      }
    },
    check2FA() {
      if (!this.edited.TwoFactor) {
        this.edited.DuoUsername = null
      }
    },
    snackbar: function (data) {
      this.sb_color = data.color
      this.sb_msg = data.msg
      this.sb_delay = data.delay || 4000
      this.sb_model = true
    },
    newUser: function () {
      this.formAction = 'Insert'
      this.edited = Object.assign({}, this.default)
      this.permissions = []
      this.userDialog = true
    },
    editUser: function (item) {
      this.formAction = 'Update'
      this.editedIndex = this.adminTableData.indexOf(item)
      this.edited = Object.assign({}, item)
      this.copyCurrentPermissions()
      this.userDialog = true
    },
    deleteUserDialog () {
      this.getSalesAgents()
      this.deleteuserDialog = true
    },
    deleteUser: function (user) {
      axios.post(`${this.$store.getters.NODEURL}/admin/users/delete/${user.pID}`, { withCredentials: true })
           .then((result) => { console.log(result) })
           .catch((err) => { console.log(err) })
           .finally(() => { })
    },
    disableUser: function (user) {
      axios
        .post(`${this.$store.getters.NODEURL}/admin/users/disable/${user.pID}`, {
          withCredentials: true
        })
        .then((result) => {
          if (result.result === true) {
            this.snackbar({
              msg: 'User account was disabled.',
              color: 'success'
            })
          }
        })
        .catch((err) => {
          this.snackbar({
            msg: 'Failed to disable user account.',
            err: err,
            color: 'error'
          })
        })
        .finally(() => {
          this.load()
        })
    },
    resetUser: function (user) {
      axios
        .post(`${this.$store.getters.NODEURL}/admin/users/reset/${user.pID}`, {
          withCredentials: true
        })
        .then((result) => {
          if (result.data.result === true) {
            this.snackbar({
              msg: 'Password was reset to system default.',
              color: 'success'
            })
          }
        })
        .catch((err) => {
          this.snackbar({
            msg: 'Failed to reset user password.',
            err: err,
            color: 'error'
          })
        })
        .finally(() => {
          this.load()
        })
    },
    load: function () {

      this.adminLoading = true

      const calendarDate = (days) => {
        let d = new Date('1/1/2021')
        d.setDate(d.getDate() + days)
        return d
      }

      axios
        .get(`${this.$store.getters.NODEURL}/admin/users`, {
          withCredentials: true
        })
        .then((result) => {

          this.slackUserList = result.data.slack.map((v) => {
            return {
              text: v.real_name,
              value: v.slackid
            }
          })

          let dt = result.data.projections.value.split(',')

          this.adminTableData = result.data.users

          let days = [+dt[0], +dt[1], +dt[2], +dt[3]]

          for (let i = 0; i < 4; ++i)
            this.quarterlyCutoff[i + 1] = calendarDate(days[i]).toISOString().substr(0, 10)

          let p = result.data.slackgroup[0].value.split(',')

          this.fobListNJ = p.map(v => {
            return {
              text: this.slackUserList.filter((r) => {
                return r.value == v
              })[0].text,
              value: v
            }
          })

          p = result.data.slackgroup[1].value.split(',')

          this.fobListSMG = p.map(v => {
            return {
              text: this.slackUserList.filter((r) => {
                return r.value == v
              })[0].text,
              value: v
            }
          })

        })
        .catch((err) => {
          console.error(err)
          this.snackbar({
            msg: 'Failed to load administrative user list.',
            color: 'error'
          })
        })
        .finally(() => {
          this.adminLoading = false
        })
    },
    updateUser: function () {
      const formdata = new FormData()
      Object.keys(this.edited).forEach((key) => {
        formdata.append(key, this.edited[key])
      })
      if (this.formAction === 'Update') {
        axios
          .post(`${this.$store.getters.NODEURL}/admin/users/update/${this.edited.pID}`, formdata, {
            withCredentials: true
          })
          .then((result) => {
            if (result.data.result) {
              this.snackbar({
                msg: `Updated user record - ${result.data.message}`,
                color: 'success'
              })
              this.userDialog = false
            } else {
              this.snackbar({
                msg: `Failed to update user record - ${result.data.message}`,
                color: 'error'
              })
            }
          })
          .catch((err) => {
            this.snackbar({
              msg: `Failure in updating record - ${err}`,
              err: err,
              color: 'error'
            })
          })
          .finally(() => {
            this.load()
          })
      } else {
        axios
          .post(`${this.$store.getters.NODEURL}/admin/users/create/`, formdata, {
            withCredentials: true
          })
          .then((result) => {
            if (result.data.result) {
              this.snackbar({
                msg: `Created new user`,
                color: 'success'
              })
              this.userDialog = false
            } else {
              this.snackbar({
                msg: `Failed to create user record - ${result.data.message}`,
                color: 'error'
              })
            }
          })
          .catch((err) => {
            this.snackbar({
              msg: `Failed to create user record - ${err}`,
              color: 'error'
            })
          })
          .finally(() => {
            this.load()
          })
      }
    },
    seedSMG: function () {
      this.fobListSMG = [...this.fobListNJ]
    },
    seedNJ: function () {
      this.fobListNJ = [...this.fobListSMG]
    },
    getSalesAgents: function () {
      let list = []
      axios
        .get(`${this.$store.getters.NODEURL}/salespeople`, { withCredentials: true })
        .then(response => {
          let id = 0, sp = response.data.data
          for (var k in sp) { list.push({ id: id++, text: `${sp[k].First} ${sp[k].Last} (${sp[k].NavInitials})`, value: sp[k].NavInitials }) }
          this.salesAgents = list // update the list of agent names
        })
        .catch(err => {
          console.log(err)
          this.slSalesAgents = []
        })
    }
  }
}
</script>

<style scoped>
.smaller >>> label { font-size: 12px; }
</style>
